
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import { themes } from "mdx-deck";
import { dark, highlight } from "@mdx-deck/themes";
import Img from "./components/Img";
import Layout from "./components/Layout";
import Link from "./components/Link";
import Column from "./components/Column";
export const theme = { ...dark,
  ...highlight
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};
const Head = makeShortcode("Head");
const Steps = makeShortcode("Steps");
const Invert = makeShortcode("Invert");
const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Head mdxType="Head">
  <title>How to Build High Performance Website</title>
  <link rel="icon" type="image/png" href={require("./images/computer.png")} />
  <meta property="og:image" content={require("./images/banner.png")} />
  <meta name="og:description" content="Discuss improvement that we can do especially with Vue" />
  <script async data-api="/_hive" src="/bee.js" />
    </Head>
    <Layout mdxType="Layout">
      <h1>{`How to Build High Performance Website`}</h1>
      <p>{`by Ilham Wahabi`}</p>
    </Layout>
    <hr></hr>
    <h1>{`About Me`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Frontend Developer`}</li>
        <li parentName="ul">{`Informatics ITB'16`}</li>
        <li parentName="ul">{`Ex. eHealth, Dekoruma, Chatbiz, Stoqo`}</li>
        <li parentName="ul"><Link href="https://github.com/iwgx" mdxType="Link">Github</Link> & <Link href="https://twitter.com/ilhamwahabigx" mdxType="Link">
  Twitter
          </Link></li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`Why Performance Matters?`}</h1>
    <hr></hr>
    <h1>{`bad found :(`}</h1>
    <hr></hr>
    <Layout mdxType="Layout">
      <blockquote>
        <p parentName="blockquote">{`Aberdeen Group found that a `}<strong parentName="p">{`1s slow down`}</strong>{` resulted `}<strong parentName="p">{`11% fewer page views`}</strong>{`, `}<strong parentName="p">{`7% less conversion`}</strong></p>
      </blockquote>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <blockquote>
        <p parentName="blockquote">{`Akamai found that `}<strong parentName="p">{`2s delay`}</strong>{` in web page load time increase `}<strong parentName="p">{`bounce rates by 103%`}</strong></p>
      </blockquote>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <blockquote>
        <p parentName="blockquote">{`Google found that a `}<strong parentName="p">{`2% slower page`}</strong>{` resulted in `}<strong parentName="p">{`2% fewer searches`}</strong>{`, which means 2% fewer ads show`}</p>
      </blockquote>
    </Layout>
    <hr></hr>
    <h1>{`good found :)`}</h1>
    <hr></hr>
    <Layout mdxType="Layout">
      <blockquote>
        <p parentName="blockquote">{`A `}<strong parentName="p">{`400ms improvement`}</strong>{` in performance resulted in a `}<strong parentName="p">{`9% increase in traffic`}</strong>{` at Yahoo`}</p>
      </blockquote>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <blockquote>
        <p parentName="blockquote"><strong parentName="p">{`100ms improvement`}</strong>{` in performance results in `}<strong parentName="p">{`1% increase in overall revenue`}</strong>{` at Amazon`}</p>
      </blockquote>
    </Layout>
    <hr></hr>
    <h1>{`Takeaway`}</h1>
    <Steps mdxType="Steps">
      <h3>{`Even small improvement have huge effect`}</h3>
    </Steps>
    <hr></hr>
    <Invert mdxType="Invert">
      <h1>{`3 Aspect of Performance`}</h1>
      <Column data={[{
        label: "Javascript",
        img: require("./images/coding.png")
      }, {
        label: "Load",
        img: require("./images/loader.png")
      }, {
        label: "Rendering",
        img: require("./images/render.png")
      }]} mdxType="Column" />
    </Invert>
    <hr></hr>
    <h1>{`Steve Golden Rules of Performance`}</h1>
    <hr></hr>
    <h2>{`Doing less stuff takes less time`}</h2>
    <hr></hr>
    <h2>{`If you can do it later. Do it later`}</h2>
    <hr></hr>
    <h1>{`#1 Javascript Performance`}</h1>
    <hr></hr>
    <Img src={require("./images/js.png")} mdxType="Img" />
    <hr></hr>
    <h1>{`Try avoid nested function`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function cubic(a) {
  // Will parsed repeatedly
  function square(b) {
    return b * b;
  }

  return square(a) * a;
}
`}</code></pre>
    <hr></hr>
    <h1>{`Use function consistently`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function add(a, b) {
  return a + b;
}

add(1, 2);

add(2, 3); // faster

add("1", "a"); // slow
`}</code></pre>
    <hr></hr>
    <h1>{`Takeaways`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Ship less code`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Consider type system`}</p>
        </li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Debugging with worsen performance`}</h2>
    <Img src={require("./images/performance.png")} style={{
      width: "60%",
      maxWidth: 1080
    }} mdxType="Img" />
    <hr></hr>
    <h2>{`Benchmark Javascript Operation with `}<Link href="https://perf.link/" mdxType="Link">{`perf.link`}</Link></h2>
    <Img src={require("./images/perflink.png")} mdxType="Img" />
    <hr></hr>
    <h1>{`#2 Load Performance`}</h1>
    <hr></hr>
    <h2>{`Debugging with slow connection`}</h2>
    <Img src={require("./images/network.png")} mdxType="Img" />
    <hr></hr>
    <h1>{`Code splitting`}</h1>
    <hr></hr>
    <h2>{`Imagine this scenario`}</h2>
    <Steps mdxType="Steps">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Your website have `}<em parentName="p">{`home page`}</em>{`, `}<em parentName="p">{`about page`}</em>{`, and `}<em parentName="p">{`register page`}</em></p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`User visit your `}<em parentName="p">{`home page`}</em></p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Is user also need to load your `}<em parentName="p">{`about page`}</em>{` & `}<em parentName="p">{`register page`}</em>{`?`}</p>
        </li>
      </ol>
    </Steps>
    <hr></hr>
    <Invert mdxType="Invert">
      <h2>{`Just load what matters`}</h2>
      <Img src={require("./images/codesplitting.png")} mdxType="Img" />
    </Invert>
    <hr></hr>
    <h2>{`2 types:`}</h2>
    <Steps mdxType="Steps">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Route based`}</p>
          <ul parentName="li">
            <li parentName="ul">{`Split route that likely will not visited often`}</li>
            <li parentName="ul">{`Ex: Split career page and about page`}</li>
          </ul>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Component based`}</p>
          <ul parentName="li">
            <li parentName="ul">{`Split component that will not used immediately`}</li>
            <li parentName="ul">{`Ex: Split modal and drawer`}</li>
          </ul>
        </li>
      </ol>
    </Steps>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-vue"
      }}>{`<template>
  <lazy-modal />
</template>

<script>
/* load even if user will not use */
// import Modal from "./Modal.vue";

/* just load when used */
const LazyModal = () => import("Modal.vue");
export default {
  components: { LazyModal },
};
</script>
`}</code></pre>
    <hr></hr>
    <h3><Link href="https://nuxtjs.org/" mdxType="Link">{`Nuxt.js`}</Link>{` use route-based code splitting as default`}</h3>
    <Img src={require("./images/nuxt.png")} mdxType="Img" />
    <hr></hr>
    <h2>{`This is JUST (modern) Javascript`}</h2>
    <hr></hr>
    <h1>{`Load Image`}</h1>
    <hr></hr>
    <h2>{`Keep small`}</h2>
    <Steps mdxType="Steps">
      <h2>{`and`}</h2>
      <h2>{`Just load what screen need`}</h2>
    </Steps>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<img src="small.jpg" srcset="medium.jpg 500w, large.jpg 1000w" />
`}</code></pre>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`If your device width is 320px`}</li>
        <li parentName="ul">{`And non retina (1x)`}</li>
        <li parentName="ul">{`Calculation:`}<ul parentName="li">
            <li parentName="ul">{`500 / 320 = 1.5625`}</li>
            <li parentName="ul">{`1000 / 320 = 3.125`}</li>
          </ul></li>
        <li parentName="ul">{`Then your browser will choose `}<strong parentName="li">{`medium.jpg`}</strong></li>
        <li parentName="ul">{`Since it nearest >= than 1`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You need multiple file for one image`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You need to compress it manually`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Syntax not too intuitive`}</p>
      </li>
    </ul>
    <hr></hr>
    <h2><Link href="https://www.imgix.com/" mdxType="Link">{`Imgix`}</Link></h2>
    <Img src={require("./images/imgix.png")} mdxType="Img" />
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`http://domain/img.png?auto=compress&w=600&h=400
`}</code></pre>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Just need 1 master file`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`No need compress manually`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`And another great feature!`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`official: `}<Link href="https://github.com/imgix/vue-imgix" mdxType="Link">{`vue-imgix`}</Link></p>
        </li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`How to check package size?`}</h1>
    <hr></hr>
    <h1><Link href="https://bundlephobia.com/" mdxType="Link">{`Bundlephobia`}</Link></h1>
    <Img src={require("./images/bundlephobia.png")} mdxType="Img" />
    <hr></hr>
    <h1>{`Alternative package`}</h1>
    <Steps mdxType="Steps">
      <p>{`take with grain of salt`}</p>
    </Steps>
    <hr></hr>
    <h2><Link href="https://bundlephobia.com/result?p=dayjs" mdxType="Link">{`dayjs`}</Link>{`, for `}<Link href="https://bundlephobia.com/result?p=moment" mdxType="Link">{`momentjs`}</Link></h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`25x smaller`}</li>
        <li parentName="ul">{`(almost) same API`}</li>
        <li parentName="ul">{`tree-shakeable`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2><Link href="https://bundlephobia.com/result?p=redaxios" mdxType="Link">{`redaxios`}</Link>{`, for `}<Link href="https://bundlephobia.com/result?p=axios" mdxType="Link">{`axios`}</Link></h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`~800 byte`}</li>
        <li parentName="ul">{`same API`}</li>
        <li parentName="ul">{`use Fetch`}</li>
        <li parentName="ul">{`tree-shakeable`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2><Link href="https://bundlephobia.com/result?p=nanoid" mdxType="Link">{`nanoid`}</Link>{`, for `}<Link href="https://bundlephobia.com/result?p=uuid" mdxType="Link">{`uuid`}</Link></h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`6x smaller`}</li>
        <li parentName="ul">{`tree-shakeable`}</li>
        <li parentName="ul">{`side-effect free`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2><Link href="https://bundlephobia.com/result?p=lodash-es" mdxType="Link">{`lodash-es`}</Link>{`, for `}<Link href="https://bundlephobia.com/result?p=lodash" mdxType="Link">{`lodash`}</Link></h2>
    <Steps mdxType="Steps">
      <p>{`even better`}</p>
      <h2>{`lodash.`}{`[methodName]`}</h2>
    </Steps>
    <hr></hr>
    <h2>{`Or use it with`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import add from "lodash/add";

add(6, 4);
`}</code></pre>
    <hr></hr>
    <h1>{`#3 Render Performance`}</h1>
    <hr></hr>
    <h1>{`Reflow`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Blocking operation`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Consume CPU`}</p>
        </li>
      </ul>
    </Steps>
    <hr></hr>
    <Layout mdxType="Layout">
      <p>{`Whenever the geometry of an element changes, the browser has to reflow the page`}</p>
      <p>{`Reflow an element causes a reflow of its parent and children`}</p>
    </Layout>
    <hr></hr>
    <h2>{`Cause`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Changing the font`}</li>
        <li parentName="ul">{`Content changes`}</li>
        <li parentName="ul">{`Add / remove element`}</li>
        <li parentName="ul">{`Add / remove classes`}</li>
        <li parentName="ul">{`Calculating size`}</li>
        <li parentName="ul">{`Changing size / position`}</li>
        <li parentName="ul">{`(Even more...)`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`How to Avoid?`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Change classes at the lowest level`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Avoid repeatedly modifying inline style`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Avoid table layouts`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`(Even more...)`}</p>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Do Batch DOM Manipulation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// BAD
parent.append(child1);
parent.append(child2);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// GOOD
fragment.append(child1);
fragment.append(child2);

parent.append(fragment);
`}</code></pre>
    <hr></hr>
    <h2>{`Layout Trashing`}</h2>
    <hr></hr>
    <Layout mdxType="Layout">
      <p>{`we `}<strong parentName="p">{`update`}</strong>{` something`}</p>
      <Steps mdxType="Steps">
        <p>{`then we `}<strong parentName="p">{`read`}</strong>{` something`}</p>
        <p>{`browser be like`}</p>
        <h4>{`wait, you just changed something before. Let me reflow the page again to get latest value`}</h4>
      </Steps>
    </Layout>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// BAD
const width1 = element1.offsetWidth;
element1.style.width = width1 * 2;

const width2 = element2.offsetWidth;
element1.style.width = width2 * 2;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// GOOD
const width1 = element1.offsetWidth;
const width2 = element2.offsetWidth;

element1.style.width = width1 * 2;
element2.style.width = width2 * 2;
`}</code></pre>
    <hr></hr>
    <h1>{`Solution`}</h1>
    <Steps mdxType="Steps">
      <h3>{`Separate read and write`}</h3>
    </Steps>
    <hr></hr>
    <Layout mdxType="Layout">
      <p>{`If you can change the visual appearance of an element by adding a `}<strong parentName="p">{`CSS class`}</strong></p>
      <p>{`Do that, you'll avoid accidental trashing`}</p>
    </Layout>
    <hr></hr>
    <h1>{`Have long list of data?`}</h1>
    <Img src={require("./images/twitter.png")} mdxType="Img" />
    <hr></hr>
    <h2>{`Every time you scroll a long list`}</h2>
    <Steps mdxType="Steps">
      <h4>{`There is expensive DOM nodes creation`}</h4>
      <h3>{`and`}</h3>
      <h4>{`Not visible component still stored in memory`}</h4>
    </Steps>
    <hr></hr>
    <h2>{`Use `}<Link href="https://github.com/Akryum/vue-virtual-scroller" mdxType="Link">{`vue-virtual-scroller`}</Link></h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`By Vue core team`}</li>
        <li parentName="ul">{`only renders the visible items`}</li>
        <li parentName="ul">{`as the user scrolls,`}</li>
        <li parentName="ul">{`it reuses all components & DOM nodes`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <Invert mdxType="Invert">
      <h1>{`Maybe you don't need this?`}</h1>
      <Steps mdxType="Steps">
        <h2>{`ever heard of pagination?`}</h2>
      </Steps>
    </Invert>
    <hr></hr>
    <h1>{`Audit`}</h1>
    <hr></hr>
    <h1>{`It's an irony`}</h1>
    <hr></hr>
    <h1>{`User access our web...`}</h1>
    <Steps mdxType="Steps">
      <h2>{`Using low spec device`}</h2>
      <h2>{`In slow connection`}</h2>
    </Steps>
    <hr></hr>
    <Invert mdxType="Invert">
      <h1>{`Meanwhile developer...`}</h1>
      <Steps mdxType="Steps">
        <h2>{`Using high spec device`}</h2>
        <h2>{`In high speed connection`}</h2>
      </Steps>
    </Invert>
    <hr></hr>
    <h1>{`Auditing Tools`}</h1>
    <hr></hr>
    <h2>{`1. `}<Link href="https://chrome.google.com/webstore/detail/lighthouse/blipmdconlkpinefehnmjammfjpmpbjk" mdxType="Link">{`Lighthouse`}</Link></h2>
    <Img src={require("./images/lighthouse.png")} mdxType="Img" />
    <hr></hr>
    <h2>{`2. `}<Link href="https://chrome.google.com/webstore/detail/web-vitals/ahfhijdlegdabablpippeagghigmibma" mdxType="Link">{`Web Vitals`}</Link></h2>
    <Img src={require("./images/webvitals.png")} mdxType="Img" />
    <hr></hr>
    <h2>{`3. `}<Link href="https://web.dev/measure/" mdxType="Link">{`Measure`}</Link></h2>
    <Img src={require("./images/measure.png")} mdxType="Img" />
    <hr></hr>
    <h2>{`4. `}<Link href="https://developers.google.com/speed/pagespeed/insights/" mdxType="Link">{`Pagespeed Insight`}</Link></h2>
    <Img src={require("./images/pagespeedinsight.png")} mdxType="Img" />
    <hr></hr>
    <h2>{`5. `}<Link href="https://testmysite.io/" mdxType="Link">{`Testmysite`}</Link></h2>
    <Img src={require("./images/testmysite.png")} mdxType="Img" />
    <hr></hr>
    <h2>{`6. `}<Link href="https://www.webpagetest.org/" mdxType="Link">{`Webpagetest`}</Link></h2>
    <Img src={require("./images/webpagetest.png")} mdxType="Img" />
    <hr></hr>
    <h1>{`It's Audit Time!`}</h1>
    <hr></hr>
    <h2>{`Things not discussed here`}</h2>
    <ul>
      <li parentName="ul">{`Performance CSS animation`}</li>
      <li parentName="ul">{`Load font effectively`}</li>
      <li parentName="ul">{`Caching`}</li>
      <li parentName="ul">{`Service Worker`}</li>
      <li parentName="ul">{`Unused code removal`}</li>
      <li parentName="ul">{`Chrome Performance tab`}</li>
      <li parentName="ul">{`Much more...`}</li>
    </ul>
    <hr></hr>
    <h2>{`That's all folks`}</h2>
    <Steps mdxType="Steps">
      <h1>{`Any Question?`}</h1>
    </Steps>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;